<template>
  <v-container fluid my-16>
    <v-row>
      <v-col>
        <img v-bind:src="require('../assets/ssb_logo4.png')" width="300"/>
        <p class="subtitle">Plan your next catering event or order catering service.</p>
        <br/>
        <h2>Contact us</h2>
        <h4 style="color:#0E618B;font-weight:400">Phone: (650) 342-5753</h4>
        <h4 style="color:#BF1866;font-weight:400">orders@ssbcatering.com</h4>
        <div class="container">
            <section class="special">
                <v-btn icon href="https://www.yelp.com/biz/servers-standing-by-catering-san-mateo-3">
                  <img height="32" width="32" v-bind:src="require('../assets/yelp.svg')"/>
                </v-btn>
                <v-btn icon href="https://www.facebook.com/ServersStandingBy">
                  <img height="32" width="32" v-bind:src="require('../assets/facebook.svg')"/>
                  <!-- <md-icon class="mdi mdi-facebook"/> -->
                </v-btn>
            </section>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: 'ContactPage'
}
</script>


<style lang="scss" scoped>

h2 {
	line-height: 1em;
	font-weight: 300;
	margin: 0 0 0.5em 0;
	padding: 0;
	letter-spacing: 0.125em;
	text-transform: uppercase;
}


.subtitle {
  color: rgb(166, 170, 173);
  font-size: .9em;
}

</style>
