<template>
<div>
  <v-container>
    <v-row>
      <v-col>
        <h1>Gallery</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="c in 3" :key="c" cols="12" sm="4">
        <v-card flat v-for="img in images" :key="img.key" class="my-6">
              <v-img v-if="(img.key-1) % 3 == (c-1)" v-bind:src="require(`../assets/gallery/${img.file}`)" @click="showLightBox(img.key)"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <div id="myModal" class="modal" :style="{ display: lightboxDisplay }">
      <span class="close cursor" @click="hideLightBox">&times;</span>
      <div class="modal-content">
        <v-carousel hide-delimiters show-arrows-on-hover v-model="currentImage" height="auto">
          <v-carousel-item v-for="i in images" :key="i.key">
            <v-sheet>
              <v-img v-bind:src="require(`../assets/gallery/${i.file}`)"/>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </div>
  </div>
</div>
</template>


<script>

export default {
  data() {
    return {
        lightboxDisplay: "none",
        currentImage: 0,
        images: [
                { key: 1, file: "Mashed Potatoes.jpg", title: "Mashed Potatoes", detail: ""},
                { key: 2, file: "Poke_Toast.jpg", title: "Poke Toast", detail: ""},
                { key: 3, file: "Breakfast_Quesadilla.jpg", title: "Breakfast Quesadilla", detail: ""},
                { key: 4, file: "Caesar.jpg", title: "Caesar", detail: ""},
                { key: 5, file: "Cheese_App.jpg", title: "Cheese Appetizer", detail: ""},
                { key: 6, file: "Chicken_Caesar.jpg", title: "Chicken Caesar", detail: ""},
                { key: 7, file: "Asian_Quinoa.jpg", title: "Asian Quinoa", detail: "Cookies, Mini cupcakes and fruit plates"},
                { key: 8, file: "NY_Bagel_Avocado_Toast_2.jpg", title: "NY Bagel Avocado Toast 2", detail: ""},
                { key: 9, file: "NY_Bagel_Avocado_Toast.jpg", title: "NY Bagel Avocado Toast", detail: ""},
                { key: 10, file: "Avo_Toast_Spread.jpg", title: "Avocado Toast Spread", detail: ""},
                { key: 11, file: "Southwest_Quinoa_2.jpg", title: "Southwest Quinoa 2", detail: ""},
                { key: 12, file: "Roast_Chicken.jpg", title: "Roast Chicken", detail: ""},
                { key: 13, file: "Salad_Buffet.jpg", title: "Salad Buffet", detail: ""},
                { key: 14, file: "Seafood_App.jpg", title: "Seafood Appetizer", detail: ""},
                { key: 15, file: "Southwest_Quinoa.jpg", title: "", detail: "Southwest Quinoa"},
                { key: 16, file: "Thanksgiving.jpg", title: "Thanksgiving", detail: ""},
                { key: 17, file: "Spread_1.jpg", title: "Spread 1", detail: ""},
                { key: 18, file: "Spread_2.jpg", title: "Spread 2", detail: ""},
                { key: 19, file: "SSB_House_Salad.jpg", title: "SSB House Salad", detail: ""},
                { key: 20, file: "Prime_Rib_Box.jpg", title: "Prime Rib Box", detail: ""},
                { key: 21, file: "Watermelon_Salad.jpg", title: "Watermelon Salad", detail: ""},
                { key: 22, file: "Prime_Rib_Spread.jpg", title: "Prime Rib Spread", detail: ""},
              ]
    }
  },
  methods: {
    showLightBox(key) {
      this.currentImage = key-1;
      this.lightboxDisplay = "block";
    },
    hideLightBox(){
      this.lightboxDisplay = "none";
    },
  }
}

</script>


<style lang="scss" scoped>

.v-container {
  max-width: 1400px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
}

.md-card {
  width: 100%;
  display: block;
  position: relative;
  margin-top: 1.6rem;
}

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}

/* The Close Button */
.close {
  color: white;
  position: absolute;
  top: 60px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}



</style>
